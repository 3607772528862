var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ics-page-inner",
        { attrs: { title: "还款信息" } },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-width": "180px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "还款金额(元)" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.moneyFormat(_vm.payInfo.repaymentAmount, 2)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "还款期限" } }, [
                    _c("p", [
                      _vm._v(_vm._s(_vm.utils.dateFormat(_vm.payInfo.loanEnd)))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "本金" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.utils.moneyFormat(_vm.payInfo.theCapital, 2))
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "利息" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.moneyFormat(_vm.payInfo.theInterest, 2)
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "还款状态" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.statusFormat(
                            _vm.payInfo.recordStatus,
                            "recordStatus"
                          )
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm.payInfo.pay
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "还款凭证" } }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              staticClass: "text-btn",
                              attrs: { href: "javascript:" },
                              on: {
                                click: function($event) {
                                  return _vm.utils.downloadP(
                                    "repay",
                                    _vm.payInfo.pay.url
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.payInfo.pay.name || "-"))]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-col"),
              _vm.payInfo.collectionDate
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "收款日期" } }, [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.utils.dateFormat(_vm.payInfo.collectionDate)
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c("el-col"),
              _vm.payInfo.co
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "收款凭证" } }, [
                        _c("p", [
                          _c(
                            "a",
                            {
                              staticClass: "text-btn",
                              attrs: { href: "javascript:" },
                              on: {
                                click: function($event) {
                                  return _vm.utils.downloadP(
                                    "repay",
                                    _vm.payInfo.co.url
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.payInfo.co.name || "-"))]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }